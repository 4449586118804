import { ButtonSizes } from '@ui/Button/Button';
import { rem } from 'polished';
import styled from 'styled-components';

const getBackgroundByProp = (prop: {
    $color: string;
    $blackType: boolean;
    $whiteType: boolean;
    theme: { colors: { white: string; primary: string; black: string } };
}) => {
    if (prop.$color) return prop.$color;
    if (prop.$blackType) return prop.theme.colors.black;
    if (prop.$whiteType) return prop.theme.colors.white;

    return prop.theme.colors.primary;
};

export const Button = styled.button<{
    $marginTop?: number;
    $marginBottom?: number;
    $marginRight?: number;
    $marginLeft?: number;
    $fullWidth: boolean;
    $color?: string;
    $whiteType?: boolean;
    $blackType?: boolean;
    $size?: ButtonSizes;
}>`
    background: ${(prop) =>
        getBackgroundByProp(
            prop as {
                $color: string;
                $blackType: boolean;
                $whiteType: boolean;
                theme: { colors: { white: string; primary: string; black: string } };
            }
        )};
    font-size: ${(prop) =>
        prop.$size === ButtonSizes.SMALL ? prop.theme.fontSizes.textBase : prop.theme.fontSizes.textMedium};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${(prop) => (prop.$whiteType ? prop.theme.colors.primary : prop.theme.colors.white)};
    border: 2px solid ${(prop) => (prop.$blackType ? prop.theme.colors.black : prop.theme.colors.primary)};
    cursor: pointer;
    padding: ${(prop) => (prop.$size === ButtonSizes.SMALL ? `${rem(8)} ${rem(16)}` : `${rem(9)} ${rem(15)}`)};
    border-radius: ${rem(6)};
    ${(prop) => (prop.$marginTop ? `margin-top: ${rem(prop.$marginTop)}` : '')};
    ${(prop) => (prop.$marginBottom ? `margin-bottom: ${rem(prop.$marginBottom)}` : '')};
    ${(prop) => (prop.$marginRight ? `margin-right: ${rem(prop.$marginRight)}` : '')};
    ${(prop) => (prop.$marginLeft ? `margin-left: ${rem(prop.$marginLeft)}` : '')};
    ${(prop) => (prop.$fullWidth ? 'width: 100%' : '')};

    &:hover {
        opacity: 0.9;
    }

    &:disabled {
        background: ${({ theme }) => theme.colors.grey};
        border-color: ${({ theme }) => theme.colors.grey};
        opacity: 0.25;
    }
`;
