import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as string_decoder from 'string_decoder';
import { DiscountApplyStatus, DiscountApplyType, DiscountCodeType } from '../interfaces/Cart';
import { IBillingInformation, IParticipant } from '../interfaces/Checkout';
import { store } from './index';

export interface CheckoutState {
    participants: IParticipant[];
    billingInformation: IBillingInformation;
    billingPaymentMethod: string;
    note: string;
    contribution: {
        amount: number;
        type?: number;
    };
    orderNumber: number;
    countOfProductsInCart: number;
    totalPriceWithVat: number;
    totalPriceWithoutVat: number;
    discountCode: {
        code: string;
        status: number;
        value: number;
        moduleIds: { module_id: string; discountValue: number }[];
        type: DiscountCodeType;
        applyType: DiscountApplyType,
        percentageValue?: number;
    };
    items: any;
}

const initialState: CheckoutState = {
    participants: [],
    billingInformation: null,
    billingPaymentMethod: null,
    note: null,
    contribution: {
        amount: null,
        type: null,
    },
    orderNumber: null,
    countOfProductsInCart: null,
    totalPriceWithVat: 0,
    totalPriceWithoutVat: 0,
    discountCode: {
        code: null,
        status: DiscountApplyStatus.NO_DISCOUNT,
        value: 0,
        moduleIds: null,
        type: null,
        applyType: null,
        percentageValue: null,
    },
    items: [],
};

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setParticipants: (state, action: PayloadAction<IParticipant[]>) => {
            state.participants = action.payload;
        },
        setBillingInformation: (state, action: PayloadAction<IBillingInformation>) => {
            state.billingInformation = action.payload;
        },
        setBillingPaymentMethod: (state, action: PayloadAction<string>) => {
            state.billingPaymentMethod = action.payload;
        },
        setNote: (state, action: PayloadAction<string>) => {
            state.note = action.payload;
        },
        setContribution: (state, action: PayloadAction<{ amount: number; type?: number }>) => {
            state.contribution = action.payload;
        },
        setOrderNumber: (state, action: PayloadAction<number>) => {
            state.orderNumber = action.payload;
        },
        setCountOfProductsInCart: (state, action: PayloadAction<number>) => {
            state.countOfProductsInCart = action.payload;
        },
        setTotalPrices: (state, action: PayloadAction<{ totalPriceWithVat: number; totalPriceWithoutVat: number }>) => {
            state.totalPriceWithVat = action.payload.totalPriceWithVat;
            state.totalPriceWithoutVat = action.payload.totalPriceWithoutVat;
        },
        setItems: (state, action: PayloadAction<any>) => {
            state.items = action.payload;
        },
        setDiscountCode: (
            state,
            action: PayloadAction<{
                code: string;
                status: number;
                value: number;
                moduleIds: { module_id: string; discountValue: number }[];
                type: DiscountCodeType;
                applyType: DiscountApplyType,
                percentageValue?: number;
            }>
        ) => {
            state.discountCode = {
                code: action.payload.code,
                status: action.payload.status,
                value: action.payload.value,
                moduleIds: action.payload.moduleIds,
                type: action.payload.type,
                applyType: action.payload.applyType,
                percentageValue: action.payload.percentageValue,
            };
        },
        resetCheckout: (state) => {
            state.participants = initialState.participants;
            state.billingInformation = initialState.billingInformation;
            state.billingPaymentMethod = initialState.billingPaymentMethod;
            state.note = initialState.note;
            state.contribution = initialState.contribution;
            state.orderNumber = initialState.orderNumber;
            state.countOfProductsInCart = initialState.countOfProductsInCart;
            state.totalPriceWithVat = initialState.totalPriceWithVat;
            state.totalPriceWithoutVat = initialState.totalPriceWithoutVat;
            state.discountCode = initialState.discountCode;
            state.items = [];
        },
    },
});

export const {
    setParticipants,
    setBillingInformation,
    setBillingPaymentMethod,
    setNote,
    setContribution,
    setOrderNumber,
    setCountOfProductsInCart,
    setTotalPrices,
    setDiscountCode,
    resetCheckout,
    setItems,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
