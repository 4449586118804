import { MouseEventHandler } from 'react';
import { SC } from '../../styles/theme';
import { IconStyled } from './IconStyled';

export enum Icons {
    Search,
    ShoppingCart,
    Menu,
    Call,
    Service,
    Question1,
    Question2,
    Question3,
    Bezpecnost,
    Notification,
    Notice,
    FutureCourse,
    Recommendation,
    Record,
    ArrowCircleRightActive,
    ArrowCircleLeftDisabled,
    ArrowCircleLeftActive,
    ArrowCircleRightDisabled,
    File,
    Favourite,
    FavouriteFull,
    MyCourses,
    MyConferences,
    MyFavourites,
    MyMaterials,
    Danove,
    Mzdove,
    Profi,
    Sprava,
    Poradca,
    Success,
    Failed,
    Discount,
    Capacity1,
    Capacity2,
    Capacity3,
    Board,
    Envelop,
    Calendar,
    Feedback,
    Play,
    Wifi,
    Approve,
    Coffee,
    Aids,
    Facebook,
    Instagram,
    Linkedin,
    Twitter,
    Youtube,
    User,
    Message,
    Phone,
    Mail,
    CheckThin,
    Trash,
    Direction,
    Player,
    Previous,
    Next,
    Send,
    Point,
    PhoneMail,
    Settings,
    Diploma,
    Lesson,
    Student,
    ArrowDown,
    ArrowRight,
    Teacher,
    Laptop,
    MailOpen,
    Xmark,
    ChevronUp,
    ChevronDown,
}

export interface IconProps extends SC {
    width?: number;
    height?: number;
    name: Icons;
    color?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const Icon = IconStyled;
export default Icon;
