import moment from 'moment';
import { ICartItem } from '../interfaces/Cart';

export const calculateCountOfItemsInCart = (cartItems: ICartItem[]) => {
    return cartItems?.length > 0
        ? cartItems
              ?.map((item: { item: { quantity: number } }) => item.item.quantity)
              ?.reduce((prev: number, next: number) => prev + next)
        : 0;
};

export const calculateTotalAmountOfCart = (cartItems: ICartItem[]) => {
    return cartItems?.length > 0
        ? cartItems
              ?.map(
                  (item: { module: { price_with_vat: number }; item: { quantity: number } }) =>
                      item.module?.price_with_vat * item.item.quantity
              )
              ?.reduce((prev: number, next: number) => prev + next)
        : 0;
};

export const formatEventDate = (fromDate: string, toDate: string) => {
    const from = moment(fromDate);
    const to = moment(toDate);

    return to.diff(from, 'days') > 0
        ? `${moment(from).format('D. M. YYYY')} – ${moment(to).format('D. M. YYYY')}`
        : `${moment(from).format('D. M. YYYY')}`;
};

export const containsSpecialChars = (str: string) => {
    const specialChars = /[`!@#$%^&*()_+=\[\]{};':"\\|<>?~]/;

    return specialChars.test(str);
};

export const formatMoneyToEUR = (str: string | number) => {
    return str?.toLocaleString('sk-SK', {
        minimumFractionDigits: 2,
    });
};

export const formatAmountOnTwoDecimalPlaces = (num: number) =>
    (Math.round(num * 100) / 100).toFixed(2).replace('.', ',');
