import { publicAssets } from '@utils/url.utils';
import { rem } from 'polished';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createGlobalStyle } from 'styled-components';
import normalize from './normalize';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
     * {
        outline: none;
        box-sizing: border-box;
    }

    *::after,
    *::before {
        box-sizing: border-box;
    }
    
    html {
        font-family: ${({ theme }) => theme.fonts.fontBase};
        -webkit-font-smoothing: antialiased;
        color: ${({ theme }) => theme.colors.black};
    }
    
    a {
        color: inherit;
        text-decoration: unset;
    }
    
    @font-face {
        font-family: 'Montserrat';
        font-weight: 400;
        font-style: normal;
        src: url(${publicAssets('/fonts/montserrat.regular.ttf')}) format('opentype');
    }
    @font-face {
        font-family: 'Montserrat';
        font-weight: 700;
        font-style: normal;
        src: url(${publicAssets('/fonts/montserrat.bold.ttf')}) format('opentype');
    }
    @font-face {
        font-family: 'Montserrat';
        font-weight: 300;
        font-style: normal;
        src: url(${publicAssets('/fonts/montserrat.light.ttf')}) format('opentype');
    }
    
    .grecaptcha-badge {
        display: none !important;
    }
    
    .tooltip {
        background: ${({ theme }) => theme.colors.dark} !important;
    }
    
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        margin-top: ${rem(40)};
        flex-wrap: wrap;

        li {
            list-style: none;
            margin: 0 ${rem(5)} ${rem(10)} 0;
            border: unset;
            color: ${({ theme }) => theme.colors.dark};
            border-radius: ${rem(6)};
          
            a {
                width: ${rem(30)};
                height: ${rem(30)};
                display: flex;
                justify-content: center;
                align-items: center;
            }
          
            &.active {
                color: ${({ theme }) => theme.colors.black};
                background: ${({ theme }) => theme.colors.white};
                border: 1px solid ${({ theme }) => theme.colors.border};
            }
          
            &:not(.active) {
                &:hover,
                &:focus-visible {
                    cursor: pointer;
                    color: ${({ theme }) => theme.colors.primary};
                    border-color: ${({ theme }) => theme.colors.primary};
                } 
            }
        }
      
        .previous, .next {
            border: unset;
        }
      
        .previous {
            margin-right: ${rem(30)};
        }

        .next {
            margin-left: ${rem(30)};
        }
    }
`;

export default GlobalStyle;
