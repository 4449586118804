import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { ModalTypes } from '@components/Modal/Modal';
import useTranslations from '@hooks/useTranslation';
import { EMAIL_REGEX } from '../../../constants';
import { authApi } from '../../../services/auth.service';
import { openModalType } from '../../../store/ui';
import ButtonTemplate from '../../../ui/Button/Button';
import {
    ErrorLabel,
    ErrorMessage,
    InfoMessage,
    Input,
    Label,
    SuccessMessage,
    SupportContact,
    SupportDescription,
    SupportDetails,
    Wrapper,
} from './VerifyModal.elements';

export const ENTER_KEY_CODE = 13;

export interface LoginProps {
    data: {
        token: string;
    };
}

const VerifyModalTemplate: FC<LoginProps> = ({ data }) => {
    const { t } = useTranslations();

    const emailInputRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [hasEmailRequiredError, setHasEmailRequiredError] = useState(false);
    const [hasEmailUsedError, setHasEmailUsedError] = useState(false);
    const [verifyError, setVerifyError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keyup', async (event) => {
            event.preventDefault();
            if (event.keyCode === ENTER_KEY_CODE) {
                await handleSubmit();
            }
        });

        setTimeout(() => {
            if (!isOpen) {
                setIsOpen(true);
            }
        }, 50);
    }, []);

    const handleSubmit = async () => {
        setHasEmailRequiredError(false);

        if (emailInputRef?.current?.value === '' || !EMAIL_REGEX.test(emailInputRef?.current?.value)) {
            setHasEmailRequiredError(true);

            return;
        }

        try {
            setIsLoading(true);

            await authApi.shortRegister({
                token: data?.token,
                email: emailInputRef?.current?.value,
            });

            setIsVerified(true);
        } catch (error: { response: { status: number } }) {
            setIsLoading(false);
            if (error?.response?.status === 409) {
                setHasEmailUsedError(true);
            }

            if (error?.response?.status === 401) {
                setVerifyError(t('modal.login.mail.password.error'));
            }
        }
    };

    const handleLogin = () => {
        dispatch(
            openModalType({
                type: ModalTypes.LOGIN,
            })
        );
    };

    return (
        <Wrapper className={`${isOpen ? 'isOpen' : 'isClose'}`}>
            {!isVerified && <InfoMessage dangerouslySetInnerHTML={{ __html: t('modal.verify.description') }} />}
            {isVerified && <SuccessMessage dangerouslySetInnerHTML={{ __html: t('modal.verify.success') }} />}
            {!isVerified && (
                <Input
                    ref={emailInputRef}
                    type="email"
                    name="email"
                    className={hasEmailRequiredError ? 'error' : ''}
                    placeholder={t('modal.login.mail.placeholder')}
                />
            )}
            {!isVerified && hasEmailUsedError && <ErrorMessage>{t('modal.verify.error')}</ErrorMessage>}
            {!isVerified && hasEmailRequiredError && <ErrorLabel>{t('modal.verify.mail.error')}</ErrorLabel>}
            {!isVerified && (
                <ButtonTemplate
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    fullWidth
                    text={t('modal.verify.confirm')}
                />
            )}
            <ButtonTemplate onClick={handleLogin} marginTop={20} whiteType fullWidth text={t('modal.verify.back')} />
        </Wrapper>
    );
};

export default VerifyModalTemplate;
