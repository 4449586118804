import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.span`
    display: block;
    padding-bottom: ${rem(8)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    position: relative;
`;

export const ErrorLabel = styled.span`
    color: ${({ theme }) => theme.colors.danger};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    position: absolute;
    left: 0;
    bottom: -${rem(62)};
`;

export const ErrorMessage = styled.div`
    background: #f7a7a3;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const Reset = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`;

export const Input = styled.input`
    width: 100%;
    height: ${rem(45)};
    border-radius: ${rem(4)};
    padding: ${rem(5)} ${rem(10)};
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: ${rem(30)};

    &.error {
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;

export const ShowPasswordSection = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${rem(-20)};
    margin-bottom: ${rem(20)};
`;
