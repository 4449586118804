import { Icons } from '..';
import aids from './icons/aids.svg';
import approve from './icons/approve.svg';
import arrowCircleLeftActive from './icons/arrowCircleLeftActive.svg';
import arrowCircleLeftDisabled from './icons/arrowCircleLeftDisabled.svg';
import arrowCircleRightActive from './icons/arrowCircleRightActive.svg';
import arrowCircleRightDisabled from './icons/arrowCircleRightDisabled.svg';
import arrowDown from './icons/arrowDown.svg';
import arrowRight from './icons/arrowRight.svg';
import bezpecnost from './icons/bezpecnost.svg';
import board from './icons/board.svg';
import calendar from './icons/calendar.svg';
import call from './icons/call.svg';
import capacity1 from './icons/capacity1.svg';
import capacity2 from './icons/capacity2.svg';
import capacity3 from './icons/capacity3.svg';
import checkThin from './icons/checkThin.svg';
import chevronDown from './icons/chevronDown.svg';
import chevronUp from './icons/chevronUp.svg';
import coffee from './icons/coffee.svg';
import danove from './icons/danove.svg';
import diploma from './icons/diploma.svg';
import direction from './icons/direction.svg';
import discount from './icons/discount.svg';
import envelop from './icons/envelop.svg';
import facebook from './icons/facebook.svg';
import failed from './icons/failed.svg';
import favourite from './icons/favourite.svg';
import favouriteFull from './icons/favouriteFull.svg';
import feedback from './icons/feedback.svg';
import file from './icons/file.svg';
import futureCourse from './icons/futureCourse.svg';
import instagram from './icons/instagram.svg';
import laptop from './icons/laptop.svg';
import lesson from './icons/lesson.svg';
import linkedin from './icons/linkedin.svg';
import mail from './icons/mail.svg';
import mailOpen from './icons/mailOpen.svg';
import menu from './icons/menu.svg';
import message from './icons/message.svg';
import myConferences from './icons/myConferences.svg';
import myCourses from './icons/myCourses.svg';
import myFavourites from './icons/myFavourites.svg';
import myMaterials from './icons/myMaterials.svg';
import mzdove from './icons/mzdove.svg';
import next from './icons/next.svg';
import notice from './icons/notice.svg';
import notification from './icons/notification.svg';
import settings from './icons/options.svg';
import phoneMail from './icons/phone-mail.svg';
import phone from './icons/phone.svg';
import play from './icons/play.svg';
import player from './icons/player.svg';
import point from './icons/point.svg';
import poradca from './icons/poradca.svg';
import previous from './icons/previous.svg';
import profi from './icons/profi.svg';
import question1 from './icons/question1.svg';
import question2 from './icons/question2.svg';
import question3 from './icons/question3.svg';
import recommendation from './icons/recommendation.svg';
import record from './icons/record.svg';
import search from './icons/search.svg';
import send from './icons/send.svg';
import shoppingCart from './icons/shoppingCart.svg';
import sprava from './icons/sprava.svg';
import student from './icons/student.svg';
import success from './icons/success.svg';
import teacher from './icons/teacher.svg';
import service from './icons/tools.svg';
import trash from './icons/trash.svg';
import twitter from './icons/twitter.svg';
import user from './icons/user.svg';
import wifi from './icons/wifi.svg';
import xmark from './icons/xmark.svg';
import youtube from './icons/youtube.svg';

export const iconsShared = (name: Icons) => {
    switch (name) {
        case Icons.Search:
            return search;
        case Icons.ShoppingCart:
            return shoppingCart;
        case Icons.Favourite:
            return favourite;
        case Icons.Call:
            return call;
        case Icons.FavouriteFull:
            return favouriteFull;
        case Icons.ArrowCircleRightActive:
            return arrowCircleRightActive;
        case Icons.Notification:
            return notification;
        case Icons.Notice:
            return notice;
        case Icons.Recommendation:
            return recommendation;
        case Icons.FutureCourse:
            return futureCourse;
        case Icons.Record:
            return record;
        case Icons.ArrowCircleLeftDisabled:
            return arrowCircleLeftDisabled;
        case Icons.ArrowCircleLeftActive:
            return arrowCircleLeftActive;
        case Icons.ArrowCircleRightDisabled:
            return arrowCircleRightDisabled;
        case Icons.Service:
            return service;
        case Icons.Question1:
            return question1;
        case Icons.Question2:
            return question2;
        case Icons.Question3:
            return question3;
        case Icons.Failed:
            return failed;
        case Icons.Discount:
            return discount;
        case Icons.File:
            return file;
        case Icons.Wifi:
            return wifi;
        case Icons.Approve:
            return approve;
        case Icons.Coffee:
            return coffee;
        case Icons.MyCourses:
            return myCourses;
        case Icons.MyConferences:
            return myConferences;
        case Icons.MyFavourites:
            return myFavourites;
        case Icons.MyMaterials:
            return myMaterials;
        case Icons.Aids:
            return aids;
        case Icons.Menu:
            return menu;
        case Icons.Success:
            return success;
        case Icons.Bezpecnost:
            return bezpecnost;
        case Icons.Direction:
            return direction;
        case Icons.Danove:
            return danove;
        case Icons.Mzdove:
            return mzdove;
        case Icons.Profi:
            return profi;
        case Icons.Capacity1:
            return capacity1;
        case Icons.Capacity2:
            return capacity2;
        case Icons.Capacity3:
            return capacity3;
        case Icons.Board:
            return board;
        case Icons.Feedback:
            return feedback;
        case Icons.Calendar:
            return calendar;
        case Icons.Envelop:
            return envelop;
        case Icons.User:
            return user;
        case Icons.Play:
            return play;
        case Icons.Sprava:
            return sprava;
        case Icons.Poradca:
            return poradca;
        case Icons.Facebook:
            return facebook;
        case Icons.Instagram:
            return instagram;
        case Icons.Linkedin:
            return linkedin;
        case Icons.Youtube:
            return youtube;
        case Icons.Message:
            return message;
        case Icons.Phone:
            return phone;
        case Icons.Mail:
            return mail;
        case Icons.CheckThin:
            return checkThin;
        case Icons.Trash:
            return trash;
        case Icons.Player:
            return player;
        case Icons.Previous:
            return previous;
        case Icons.Next:
            return next;
        case Icons.Send:
            return send;
        case Icons.PhoneMail:
            return phoneMail;
        case Icons.Settings:
            return settings;
        case Icons.Point:
            return point;
        case Icons.Diploma:
            return diploma;
        case Icons.Lesson:
            return lesson;
        case Icons.Student:
            return student;
        case Icons.ArrowDown:
            return arrowDown;
        case Icons.ArrowRight:
            return arrowRight;
        case Icons.Teacher:
            return teacher;
        case Icons.Twitter:
            return twitter;
        case Icons.Laptop:
            return laptop;
        case Icons.MailOpen:
            return mailOpen;
        case Icons.Xmark:
            return xmark;
        case Icons.ChevronUp:
            return chevronUp;
        case Icons.ChevronDown:
            return chevronDown;
        default:
            return '';
    }
};
