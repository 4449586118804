import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';

export const Wrapper = styled.div<{ center?: boolean }>`
    padding: ${rem(20)};
    ${(prop) => (prop.center ? 'text-align: center' : '')}};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
    }
`;

export const SuccessIcon = styled(IconTemplate)``;

export const FailedIcon = styled(IconTemplate)`
    svg {
        path {
            fill: ${({ theme }) => theme.colors.danger};
        }
    }
`;

export const Title = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h5};
`;

export const Product = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: ${rem(45)} 0;

    @media (min-width: ${breakpoints.sm}px) {
        margin-left: ${rem(40)};
        align-items: flex-start;
    }
`;

export const ProductQuantity = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    margin-bottom: ${rem(5)};

    @media (min-width: ${breakpoints.sm}px) {
        position: absolute;
        left: -${rem(50)};
        top: ${rem(2)};
    }
`;

export const ProductName = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h5};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.primary};
    padding-bottom: ${rem(10)};
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
        text-align: left;
    }
`;

export const ProductDetailRow = styled.span`
    padding-bottom: ${rem(5)};
`;

export const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: ${rem(20)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
    }
`;

export const Summary = styled.span``;

export const Buttons = styled.div`
    display: flex;
    gap: ${rem(20)};
    flex-direction: column-reverse;
    width: 100%;

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
        width: auto;
    }
`;

export const Button = styled.div`
    display: flex;
    justify-content: center;
`;
