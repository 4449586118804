import React, { useRef, useState } from 'react';
import { FC } from 'react';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import { EMAIL_REGEX } from '../../../constants';
import { authApi } from '../../../services/auth.service';
import ButtonTemplate from '../../../ui/Button/Button';
import {
    Description,
    Error,
    Input,
    Label,
    SuccessIcon,
    SuccessMessage,
    SupportContact,
    SupportDetails,
    Warning,
    WarningDescription,
    WarningTitle,
    Wrapper,
} from './PasswordModal.elements';

export interface PasswordProps {}

const PasswordModalTemplate: FC<PasswordProps> = () => {
    const { t } = useTranslations();

    const emailInputRef = useRef(null);

    const [hasEmailError, setHasEmailError] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [hasDuplicity, setHasDuplicity] = useState(false);

    const handleSubmit = async () => {
        setHasEmailError(false);

        if (emailInputRef?.current?.value === '') {
            setHasEmailError(true);

            return;
        }

        try {
            await authApi.forgotPassword(emailInputRef?.current?.value);

            setIsSent(true);
        } catch (error: { response: { status: number } }) {
            if (error?.response?.status === 409) {
                setHasDuplicity(true);
            }
        }
    };

    return (
        <Wrapper center={isSent}>
            {isSent && (
                <>
                    <SuccessIcon name={Icons.Success} />
                    <SuccessMessage
                        dangerouslySetInnerHTML={{
                            __html: t('modal.password.sent.success', { value: emailInputRef?.current?.value }),
                        }}
                    />
                </>
            )}
            {hasDuplicity && (
                <Warning>
                    <SupportDetails>
                        <WarningTitle>{t('modal.password.duplicity.title')}</WarningTitle>
                        <WarningDescription
                            dangerouslySetInnerHTML={{ __html: t('modal.password.duplicity.description1') }}
                        />
                        <WarningDescription
                            dangerouslySetInnerHTML={{ __html: t('modal.password.duplicity.description2') }}
                        />
                        <SupportContact>{t('modal.password.duplicity.contact')}</SupportContact>
                        <SupportContact>
                            <strong>Telefón:</strong> <a href={`tel:+421417053600')}`}>+421 41 7053 600</a>
                        </SupportContact>
                        <SupportContact>
                            <strong>E-mail:</strong> <a href={`mailto:sluzby@pp.sk`}>sluzby@pp.sk</a>
                        </SupportContact>
                    </SupportDetails>
                </Warning>
            )}
            {!isSent && !hasDuplicity && (
                <>
                    <Description>{t('modal.password.description')}</Description>
                    <Label>
                        {t('modal.login.mail.label')}
                        {hasEmailError && <Error>{t('modal.login.mail.error')}</Error>}
                    </Label>
                    <Input
                        ref={emailInputRef}
                        className={hasEmailError ? 'error' : ''}
                        placeholder={t('modal.login.mail.placeholder')}
                    />
                    <ButtonTemplate onClick={handleSubmit} fullWidth text={t('modal.password.submit')} />
                </>
            )}
        </Wrapper>
    );
};

export default PasswordModalTemplate;
