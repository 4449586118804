import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { ModalTypes } from '@components/Modal/Modal';
import useCart from '@hooks/useCart';
import useTranslations from '@hooks/useTranslation';
import CheckboxTemplate from '@ui/Checkbox/Checkbox';
import Cookies from 'js-cookie';
import {
    ACCESS_TOKEN_KEY,
    ACCESS_TOKEN_KEY_GUEST,
    EMAIL_REGEX,
    REFRESH_TOKEN_KEY,
    REFRESH_TOKEN_KEY_GUEST,
    ROUTES,
} from '../../../constants';
import { authApi } from '../../../services/auth.service';
import { openModalType, setIsLoggedIn } from '../../../store/ui';
import ButtonTemplate from '../../../ui/Button/Button';
import { ErrorLabel, ErrorMessage, Input, Label, Reset, ShowPasswordSection, Wrapper } from './LoginModal.elements';

export const ENTER_KEY_CODE = 13;
export const ESC_KEY_CODE = 27;

export interface LoginProps {}

const LoginModalTemplate: FC<LoginProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    const { resetUserDetails } = useCart();

    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [hasEmailError, setHasEmailError] = useState(false);
    const [hasPasswordError, setHasPasswordError] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keyup', async (event) => {
            event.preventDefault();
            if (event.keyCode === ENTER_KEY_CODE) {
                await handleSubmit();
            }
        });
    }, []);

    const handleResetClick = () => {
        dispatch(
            openModalType({
                type: ModalTypes.PASSWORD,
            })
        );
    };

    const handleVerifyEmail = (token: string) => {
        dispatch(
            openModalType({
                type: ModalTypes.VERIFY_EMAIL,
                data: {
                    token,
                },
            })
        );
    };

    const handleSubmit = async () => {
        setHasEmailError(false);
        setHasPasswordError(false);

        if (emailInputRef?.current?.value === '') {
            setHasEmailError(true);

            return;
        }

        if (passwordInputRef?.current?.value === '') {
            setHasPasswordError(true);

            return;
        }

        try {
            setIsLoading(true);

            const {
                data: { access_token, refresh_token, token },
            } = await authApi.login({
                email: emailInputRef?.current?.value,
                password: passwordInputRef?.current?.value,
            });

            if (token) {
                return handleVerifyEmail(token);
            }

            await resetUserDetails();

            Cookies.set(ACCESS_TOKEN_KEY, access_token, {});
            Cookies.set(REFRESH_TOKEN_KEY, refresh_token, { expires: 3 });
            Cookies.remove(ACCESS_TOKEN_KEY_GUEST);
            Cookies.remove(REFRESH_TOKEN_KEY_GUEST);
            dispatch(openModalType(null));
            dispatch(setIsLoggedIn(true));
            setIsLoading(false);

            if (
                router.pathname.includes(ROUTES.REGISTER) ||
                router.pathname.includes(ROUTES.ACTIVATION) ||
                router.pathname.includes(ROUTES.PASSWORD_RESET)
            ) {
                router.replace({ pathname: ROUTES.HOME });
            } else {
                router.reload();
            }
        } catch (error: { response: { status: number } }) {
            setIsLoading(false);

            if (error?.response?.status === 401) {
                setLoginError(t('modal.login.mail.password.error'));
            }
        }
    };

    const handleRegister = () => {
        dispatch(openModalType(null));

        router.push({
            pathname: ROUTES.REGISTER,
        });
    };

    useEffect(() => {
        const element = document.getElementById('password');

        if (isPasswordVisible) {
            (element as HTMLInputElement).type = 'text';
        } else {
            (element as HTMLInputElement).type = 'password';
        }
    }, [isPasswordVisible]);

    return (
        <Wrapper>
            {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
            <Label>
                {t('modal.login.mail.label')}
                {hasEmailError && <ErrorLabel>{t('modal.login.mail.error')}</ErrorLabel>}
            </Label>
            <Input
                ref={emailInputRef}
                type="email"
                name="email"
                className={hasEmailError ? 'error' : ''}
            />
            <Label>
                {t('modal.login.password.label')}
                <Reset onClick={handleResetClick}>{t('modal.password.button')}</Reset>
                {hasPasswordError && <ErrorLabel>{t('modal.login.password.error')}</ErrorLabel>}
            </Label>
            <Input
                id="password"
                ref={passwordInputRef}
                type="password"
                className={hasPasswordError ? 'error' : ''}
            />
            <ShowPasswordSection>
                <CheckboxTemplate
                    onChange={() => setIsPasswordVisible(!isPasswordVisible)}
                    checked={isPasswordVisible}
                    label={t('modal.login.password.visibility')}
                />
            </ShowPasswordSection>
            <ButtonTemplate onClick={handleSubmit} isLoading={isLoading} fullWidth text={t('modal.login.button')} />
            <ButtonTemplate
                onClick={handleRegister}
                marginTop={20}
                whiteType
                fullWidth
                text={t('modal.register.button')}
            />
        </Wrapper>
    );
};

export default LoginModalTemplate;
