import React, { Fragment } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HydrationProvider } from 'react-hydration-provider';
import { Provider, useSelector } from 'react-redux';
import NextApp, { AppProps as NextAppProps } from 'next/app';
import Router from 'next/router';
import AlertTemplate, { AlertTypes } from '@components/Alert/Alert';
import CookieTemplate from '@components/Cookie/Cookie';
import LoadingTemplate from '@components/Loading/Loading';
import ModalTemplate from '@components/Modal/Modal';
import { isValidAndRefresh } from '@utils/auth.utils';
import { parseCookieStrToObject } from '@utils/cookie.utils';
import Cookies from 'js-cookie';
import { ThemeProvider } from 'styled-components';
import {
    ACCESS_TOKEN_KEY,
    ACCESS_TOKEN_KEY_GUEST,
    COOKIES_KEY,
    REFRESH_TOKEN_KEY,
    REFRESH_TOKEN_KEY_GUEST,
    ROUTES,
    getCaptchaKeys,
} from '../constants';
import { RootState, store } from '../store';
import { setIsLoggedIn } from '../store/ui';
import GlobalStyles from '../styles/main';
import { theme } from '../styles/theme';

export interface AppState {
    error?: any;
    errorInfo?: any;
    hasError?: boolean;
    isLoading?: boolean;
}

export type AppProps = NextAppProps & { hasSetCookies: boolean };

class App extends NextApp<AppProps, AppState> {
    state = {
        hasError: false,
        isLoading: false,
    };

    constructor(props: any) {
        super(props);

        Router.events.on('routeChangeStart', () => {
            this.setState({ isLoading: true });
        });
        Router.events.on('routeChangeComplete', () => {
            this.setState({ isLoading: false });
        });
    }

    public static async getInitialProps(initProps: any) {
        const { ctx, Component } = initProps;
        let pageProps = {};

        if (ctx?.res?.statusCode === 404) {
            ctx.res.writeHead(301, { Location: ROUTES.HOME }).end();
        }

        const hasSetCookies =
            (parseCookieStrToObject(ctx?.req?.headers?.cookie) as any)?.[COOKIES_KEY] || Cookies.get(COOKIES_KEY);

        const { accessToken, refreshToken } = await refreshAndSaveTokens(ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ctx);

        if (
            (ctx?.pathname.includes(ROUTES.MY_PV) ||
                ctx?.pathname === ROUTES.MY_ACCOUNT ||
                ctx?.pathname.includes(ROUTES.ORDER)) &&
            !accessToken
        ) {
            store.dispatch(setIsLoggedIn(false));
            ctx.res
                ? ctx.res.writeHead(302, { Location: ROUTES.HOME }).end()
                : Router.push({
                      pathname: ROUTES.HOME,
                  });
        }

        // Refresh guest token if user is not logged
        if (!accessToken && !refreshToken) {
            await refreshAndSaveTokens(ACCESS_TOKEN_KEY_GUEST, REFRESH_TOKEN_KEY_GUEST, ctx);
        }

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }

        // Remove access token which was used from CMS access redirect from query
        if (
            ctx.res &&
            ctx.query.token &&
            !ctx?.pathname.includes(ROUTES.PASSWORD_RESET) &&
            !ctx?.pathname?.includes(ROUTES.ACTIVATION) &&
            !ctx?.pathname?.includes(ROUTES.GUEST)
        ) {
            ctx.res.writeHead(302, { Location: ROUTES.HOME }).end();
        }

        const namespacesRequired = ['common'];
        return {
            accessToken,
            pageProps,
            hasSetCookies: !!hasSetCookies,
            featureContext: ctx.featureContext,
            namespacesRequired,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error, errorInfo, hasError: true });
    }

    componentDidMount() {
        isValidAndRefresh(Cookies.get(ACCESS_TOKEN_KEY), Cookies.get(REFRESH_TOKEN_KEY))
            .then(({ accessToken, refreshToken }) => {
                store.dispatch(setIsLoggedIn(accessToken && refreshToken));
            })
            .catch(() => store.dispatch(setIsLoggedIn(false)));
    }

    public render() {
        const { Component, pageProps, hasSetCookies } = this.props;
        const { error = null, isLoading = false, errorInfo = null, hasError }: AppState = this.state;

        if (!hasSetCookies && typeof window != 'undefined') {
            document.getElementById('cookie').style.display = 'block';
        }

        return (
            <Fragment>
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyles />
                        <LoadingTemplate isRouterChange={isLoading} />
                        {(!hasSetCookies || (typeof window != 'undefined' && Router?.query?.updateCookies)) && (
                            <CookieTemplate />
                        )}
                        <AlertTemplate type={AlertTypes.error} />
                        <ModalTemplate />
                        <GoogleReCaptchaProvider reCaptchaKey={getCaptchaKeys().site}>
                            <HydrationProvider>
                                {pageProps?.error || hasError ? (
                                    <Fragment>{error || errorInfo || 'Error'}</Fragment>
                                ) : (
                                    <Component {...pageProps} />
                                )}
                            </HydrationProvider>
                        </GoogleReCaptchaProvider>
                    </ThemeProvider>
                </Provider>
            </Fragment>
        );
    }
}

const refreshAndSaveTokens = async (accessTokenKey: string, refreshTokenKey: string, ctx: any) => {
    const serverAccToken =
        (!ctx?.pathname?.includes(ROUTES.PASSWORD_RESET) &&
        !ctx?.pathname?.includes(ROUTES.ACTIVATION) &&
        !ctx?.pathname?.includes(ROUTES.GUEST)
            ? ctx?.query?.token
            : null) || (parseCookieStrToObject(ctx?.req?.headers?.cookie) as any)?.[accessTokenKey];
    const clientAccToken = Cookies.get(accessTokenKey);
    const serverRefreshToken = (parseCookieStrToObject(ctx?.req?.headers?.cookie) as any)?.[refreshTokenKey];
    const clientRefreshToken = Cookies.get(refreshTokenKey);

    if (!ctx?.pathname?.includes(ROUTES.GUEST)) {
        const { accessToken, refreshToken } = await isValidAndRefresh(
            serverAccToken || clientAccToken,
            serverRefreshToken || clientRefreshToken
        );

        if (accessToken) {
            if (ctx.res) {
                ctx.res.req.cookies[accessTokenKey] = accessToken;
                ctx.res.setHeader('Set-Cookie', [
                    `${accessTokenKey}=${accessToken}; path=/`,
                    `${refreshTokenKey}=${refreshToken}; path=/`,
                ]);
            } else {
                Cookies.set(accessTokenKey, accessToken);
                Cookies.set(refreshTokenKey, refreshToken);
            }
        } else {
            if (ctx.res) {
                ctx.res.setHeader('Set-Cookie', [
                    `${accessTokenKey}=deleted; Max-Age=0`,
                    `${refreshTokenKey}=deleted; Max-Age=0`,
                ]);
            } else {
                Cookies.remove(accessTokenKey);
                Cookies.remove(refreshTokenKey);
            }
        }

        return { accessToken, refreshToken };
    }

    return { accessToken: null, refreshToken: null };
};

export default App;
