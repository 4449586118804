import getConfig from 'next/config';

const config = getConfig();

const constants = {
    NEXT_PUBLIC_APP_PREFIX: config ? config.publicRuntimeConfig.basePath : '',
    NEXT_PUBLIC_HOST_API_URL: process.env.NEXT_PUBLIC_HOST_API_URL,
    NEXT_PUBLIC_AUTH_API_URL: process.env.NEXT_PUBLIC_AUTH_API_URL,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_ADS_URL: process.env.NEXT_PUBLIC_ADS_URL,
    NEXT_PUBLIC_SENTRY_DS: 'https://d622af791f1c334363fb928abf5ec77a@sentry.prod.pp-holding.sk/6',
    NEXT_PUBLIC_CAPTCHA_KEY: process.env.NEXT_PUBLIC_CAPTCHA_KEY,
    NEXT_PUBLIC_CAPTCHA_SECRET: process.env.NEXT_PUBLIC_CAPTCHA_SECRET,
};

export const getBasePath = () => {
    return constants.NEXT_PUBLIC_APP_PREFIX;
};

export const getBaseUrl = () => {
    return 'https://www.profivzdelavanie.sk';
};

export const getApiURL = () => {
    return constants.NEXT_PUBLIC_HOST_API_URL;
};

export const getAuthApiURL = () => {
    return constants.NEXT_PUBLIC_AUTH_API_URL;
};

export const getSentryDNS = () => {
    return constants.NEXT_PUBLIC_SENTRY_DS;
};

export const getCatalogUrl = () => {
    return 'https://s3.eu-west-1.amazonaws.com/marker.attachments.prod/651d3266a2b48f5c18348125/anonymous-f7412d24-0b58-4060-98d3-0f3715794fb9?AWSAccessKeyId=AKIAYOE76RXHHARRGZMC&Expires=1708089092&Signature=b6Zkpyba4RPOdKKnquO38ujBZLw%3D&response-content-disposition=inline%3B%20filename%3D%22Studio%20W%20%3F%20Katalog%20jaro%202024%20%20V2_finalni%20verze%20na%20web.pdf%22%3B%20filename%2A%3DUTF-8%27%27Studio%2520W%2520%25E2%2580%2593%2520Katalog%2520jaro%25202024%2520%2520V2_finalni%2520verze%2520na%2520web.pdf';
};

export const getImageBaseURL = () => {
    return constants.NEXT_PUBLIC_HOST_API_URL;
};

export const getAdsUrl = () => {
    return constants.NEXT_PUBLIC_ADS_URL;
};

export const getCaptchaKeys = () => {
    return {
        site: constants.NEXT_PUBLIC_CAPTCHA_KEY,
    };
};

export const getMaxPageWidth = () => {
    return 1180;
};

export const TRAININGS_LIMIT_PER_PAGE = 10;
export const CONFERENCES_LIMIT_PER_PAGE = 10;

export const MOBILE_HEADER_HEIGHT = 80;
export const DESKTOP_HEADER_HEIGHT = 130;

export const GLOBAL_SEARCH_COMPONENT_ID = 'search';
export const HEADER_COMPONENT_ID = 'header';

export const ACCESS_TOKEN_KEY = 'pv_access_token';
export const REFRESH_TOKEN_KEY = 'pv_refresh_token';
export const ACCESS_TOKEN_KEY_GUEST = 'pv_guest_access_token';
export const REFRESH_TOKEN_KEY_GUEST = 'pv_guest_refresh_token';
export const COOKIES_KEY = 'pv_cookies';

export const IS_PROD = true;

export const EMAIL_REGEX: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum FBQ_EVENTS {
    ADD_PAYMENT_INFO = 'AddPaymentInfo',
    ADD_TO_CART = 'AddToCart',
    CONTACT = 'Contact',
    LEAD = 'Lead',
    PURCHASE = 'Purchase',
    COMPLETE_REGISTRATION = 'CompleteRegistration',
}

export const ROUTES = {
    HOME: '/',
    CONTACT: '/kontakt',
    TRAININGS: '/skolenia',
    TRAINING: '/skolenie',
    CONFERENCES: '/konferencie',
    PASSWORD_RESET: '/reset-hesla',
    GUEST: '/guest-login',
    COMPANY_COURSES: '/skolenia-na-mieru',
    BLOG: '/blog',
    ARTICLE: '/clanok',
    SUBSCRIPTION: '/rocne-predplatne',
    CONFERENCE: '/konferencia',
    ABOUT_US: '/o-nas',
    ABOUT_US_REFERENCE: '/o-nas#referencie',
    CART: '/nakupny-kosik',
    SHOP_PARTICIPANTS: '/udaje-ucastnikov',
    SHOP_BILLING: '/udaje-fakturacne',
    SHOP_PAYMENT: '/sposob-platby',
    SHOP_SUMMARY: '/zhrnutie-objednavky',
    SHOP_CONFIRMATION: '/potvrdenie-objednavky',
    ORDER: '/objednavka',
    SIGNIN: '/signin',
    REGISTER: '/registracia',
    ACTIVATION: '/aktivacia-uctu',
    MY_PV: '/moje-pv',
    MY_PV_COURSES: '/moje-pv/skolenia',
    MY_PV_CONFERENCES: '/moje-pv/konferencie',
    MY_PV_RECORDS: '/moje-pv/zaznamy',
    MY_PV_FAVOURITES: '/moje-pv/oblubene',
    MY_PV_MATERIALS: '/moje-pv/materialy',
    MY_PV_NOTIFICATIONS: '/moje-pv/notifikacie',
    HOME_MY_PV_COURSES: '/#skolenia',
    HOME_MY_PV_CONFERENCES: '/#konferencie',
    HOME_MY_PV_RECORDS: '/#zaznamy',
    HOME_MY_PV_FAVOURITES: '/#oblubene',
    HOME_MY_PV_MATERIALS: '/#materialy',
    LOGIN: '/prihlasenie',
    NOT_FOUND: '/404',
    MY_TRAININGS: '/moje-produkty',
    MY_ACCOUNT: '/moj-ucet',
    MY_ACCOUNT_USER: '/moj-ucet#pouzivatel',
    MY_ACCOUNT_PASSWORD: '/moj-ucet#heslo',
    MY_ACCOUNT_NOTIFICATIONS: '/moj-ucet#notifikacie',
    MY_ACCOUNT_BONUSES: '/moj-ucet#bonusy',
    MY_ACCOUNT_ACCESS: '/moj-ucet#pristupy',
    MY_ACCOUNT_ORDERS: '/moj-ucet#objednavky',
    LECTURER: '/lektor',
    SEARCH: '/vyhladavanie',
    CONDITIONS: '/obchodne-podmienky',
    GDPR: '/ochrana-osobnych-udajov',
    MARKETING: '/suhlas-s-maketingovou-komunikaciou',
    COOKIES: '/suhlas-s-pouzivanim-cookies',
};
