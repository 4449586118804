import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import { formatMoneyToEUR } from '@utils/cart.utils';
import { ROUTES } from '../../../constants';
import { openModalType } from '../../../store/ui';
import {
    Bottom,
    Button,
    Buttons,
    FailedIcon,
    Header,
    Product,
    ProductDetailRow,
    ProductName,
    ProductQuantity,
    SuccessIcon,
    Summary,
    Title,
    Wrapper,
} from './CartProductModal.elements';

export interface CartProductProps {
    data: {
        isSuccess: boolean;
        product: {
            name: string;
            quantity: number;
            price: number;
            term: string;
            venue: string;
        };
        cart: {
            countOfItems: number;
            sumOfItems: number;
        };
    };
}

const CartProductModalTemplate: FC<CartProductProps> = ({ data }) => {
    const { t } = useTranslations();

    const router = useRouter();

    const dispatch = useDispatch();

    return (
        <Wrapper>
            {data.isSuccess ? (
                <>
                    <Header>
                        <SuccessIcon name={Icons.Success} />
                        <Title>{t('modal.cart.title')}</Title>
                    </Header>
                    <Product>
                        <ProductQuantity>{data.product.quantity} ks</ProductQuantity>
                        <ProductName>{data.product.name}</ProductName>
                        <ProductDetailRow>
                            {t('modal.cart.price')}: <strong>{formatMoneyToEUR(data.product.price)} € s DPH</strong>
                        </ProductDetailRow>
                    </Product>
                    <Bottom>
                        <Summary>
                            {t('modal.cart.content')}{' '}
                            <strong>
                                {data.cart.countOfItems} {data.cart.countOfItems == 1 ? t('modal.cart.item.one') : ''}
                                {data.cart.countOfItems > 1 && data.cart.countOfItems <= 4
                                    ? t('modal.cart.item.more')
                                    : ''}
                                {data.cart.countOfItems > 4 ? t('modal.cart.item.multiple') : ''} za{' '}
                                {formatMoneyToEUR(data.cart.sumOfItems)} € s DPH
                            </strong>
                        </Summary>
                        <Buttons>
                            <ButtonTemplate
                                onClick={() => dispatch(openModalType(null))}
                                text={t('modal.cart.button.back')}
                                whiteType
                            />
                            <ButtonTemplate
                                onClick={() => {
                                    dispatch(openModalType(null));
                                    router.push(ROUTES.CART);
                                }}
                                text={t('modal.cart.button.continue')}
                            />
                        </Buttons>
                    </Bottom>
                </>
            ) : (
                <>
                    <Header>
                        <FailedIcon name={Icons.Failed} />
                        <Title>{t('modal.cart.error')}</Title>
                    </Header>
                    <Button>
                        <ButtonTemplate marginTop={40} onClick={() => dispatch(openModalType(null))} text={'Zpět'} />
                    </Button>
                </>
            )}
        </Wrapper>
    );
};

export default CartProductModalTemplate;
