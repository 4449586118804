import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
    &.isOpen {
        transform: translateX(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }

    &.isClose {
        transform: translateX(100%);
        opacity: 0;
        transition: transform 1s, opacity 1s;
    }
`;

export const Label = styled.span`
    display: block;
    padding-bottom: ${rem(8)};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    position: relative;
`;

export const ErrorLabel = styled.span`
    color: ${({ theme }) => theme.colors.danger};
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    position: absolute;
    left: 0;
    bottom: ${rem(115)};
`;

export const InfoMessage = styled.div`
    background: ${({ theme }) => theme.colors.greyMedium};
    padding: ${rem(14)};
    margin-bottom: ${rem(25)};
    border-radius: ${rem(4)};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${rem(20)};
`;

export const SuccessMessage = styled.div`
    margin-bottom: ${rem(15)};
    border-radius: ${rem(4)};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${rem(20)};
`;

export const ErrorMessage = styled.div`
    margin-bottom: ${rem(25)};
    margin-top: ${rem(-10)};
    border-radius: ${rem(4)};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${rem(20)};
    color: ${({ theme }) => theme.colors.danger};
`;

export const SupportDescription = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${rem(20)};
    margin-top: ${rem(30)};
`;

export const SupportDetails = styled.div``;

export const SupportContact = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    line-height: ${rem(20)};

    a {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const Input = styled.input`
    width: 100%;
    height: ${rem(45)};
    border-radius: ${rem(4)};
    padding: ${rem(5)} ${rem(10)};
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: ${rem(30)};

    &.error {
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;
